import { ProductPrice } from '../Price/ProductPrice';
import Image from 'components/Basic/Image/Image';
import { ProductColorChooser } from 'components/Blocks/Product/ProductColorChooser/ProductColorChooser';
import { DiscountPrice } from 'components/Blocks/Product/Price/DiscountPrice';
import { StockOnTheWay } from 'components/Blocks/Product/StockOnTheWay';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { FC, ImgHTMLAttributes, RefObject, useState } from 'react';
import { ListedProductType } from 'types/product';
import { onGtmProductClickEventHandler } from 'helpers/gtm/eventHandlers';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { ButtonsAction } from '../ButtonsAction';
import { isProductToDelivery } from 'helpers/product/toDelivery';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { ParamsVariantEnum, ProductInformationOnTile } from '../ProductInformationOnTile';
import { ProductFlags } from '../ProductFlags';
import { ProductAction } from '../ProductAction';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';
import { mapVariantsColors } from 'connectors/products/Products';
import { mapProductPriceData } from 'connectors/price/Prices';
import { resolveStockOnTheWayOptions } from 'helpers/product/resolveStockOnTheWayOptions';

const TEST_IDENTIFIER = 'blocks-product-sliderproductitem-';

type ProductsSliderItemProps = {
    product: ListedProductType | ListedProductAccessoriesFragmentApi;
    gtmListName: GtmListNameType;
    listIndex: number;
    gtmMessageOrigin: GtmMessageOriginType;
    productAvailabilityRef: RefObject<HTMLDivElement>;
    productAvailabilityHeight: number;
    isContentVisible: boolean;
    loading: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

const ProductsSliderItem: FC<ProductsSliderItemProps> = (props) => {
    const t = useTypedTranslationFunction();
    const [productImage, setProductImage] = useState(props.product.firstImage);
    const { url, currencyCode } = useDomainConfig();
    const productHref = props.product.slug;
    const colorVariants =
        props.product.__typename === 'MainVariant' ? mapVariantsColors(props.product.colorVariants) : undefined;

    const onMouseEnterHandler = () => {
        if (props.product.secondImage !== null) {
            setProductImage(props.product.secondImage);
        }
    };
    const onMouseLeaveHandler = () => {
        if (props.product.secondImage !== null) {
            setProductImage(props.product.firstImage);
        }
    };

    const { showStockOnTheWay, stockOnTheWaySlug } = resolveStockOnTheWayOptions(props.product, colorVariants);

    return (
        <div
            className={twJoin(
                'relative w-[303px] min-w-[303px] pr-[13px] text-base hover:text-primary',
                'xl:w-1/4 xl:min-w-[calc(100%/4)]',
                'xxl:w-1/5 xxl:min-w-[calc(100%/5)]',
                'last:w-[310px] last:min-w-[310px] last:pr-5',
                'last:xl:w-1/4 last:xl:min-w-[calc(100%/4)] last:xl:pr-[13px]',
                'last:xxl:w-1/5 last:xxl:min-w-[calc(100%/5)]',
            )}
            data-testid={TEST_IDENTIFIER + props.product.catalogNumber}
        >
            <ProductFlags
                isProductList
                flags={props.product.flags}
                discountPercent={
                    typeof props.product.price.discountPercent === 'string'
                        ? Number.parseFloat(props.product.price.discountPercent)
                        : props.product.price.discountPercent
                }
            />

            <div className="relative flex h-full flex-col rounded-lg bg-creamWhite text-left">
                {props.isContentVisible && (
                    <>
                        <div
                            className={twJoin(
                                'relative mb-5 mt-[18px] flex w-full flex-col items-center justify-center px-[15px]',
                                'sm:pl-[10px]',
                                'md:mb-0 md:h-[275px] md:justify-center md:pb-[65px] md:pl-0',
                            )}
                            data-testid={TEST_IDENTIFIER + 'image'}
                        >
                            <ExtendedNextLink
                                href={productHref}
                                type="product"
                                className={twJoin(
                                    'relative mb-12 h-[160px] w-[160px] bg-[transparent] text-[0] no-underline md:mb-0',
                                    'sm:h-[133px] sm:w-[133px]',
                                    'md:h-[210px] md:w-[210px]',
                                    '[&_img]:flex [&_img]:h-full [&_img]:!max-h-full [&_img]:mix-blend-multiply',
                                )}
                                onClick={() =>
                                    onGtmProductClickEventHandler(
                                        props.product,
                                        props.gtmListName,
                                        props.listIndex,
                                        url,
                                    )
                                }
                                onMouseOver={onMouseEnterHandler}
                                onMouseLeave={onMouseLeaveHandler}
                            >
                                <Image
                                    src={productImage?.url}
                                    width={210}
                                    height={210}
                                    alt={props.product.fullName}
                                    title={props.product.fullName}
                                    loading={props.loading}
                                    className="h-full w-full"
                                    sizes="210px"
                                />
                            </ExtendedNextLink>
                            {!!colorVariants?.length && (
                                <div
                                    className={twMerge(
                                        'absolute bottom-0 z-above flex w-[85%] justify-center px-[15px]',
                                    )}
                                >
                                    <ProductColorChooser
                                        colors={colorVariants}
                                        smallVariant
                                        setProductImage={setProductImage}
                                        productImage={props.product.firstImage}
                                        loading={props.loading}
                                        disableHintForFilteredOutVariants={true}
                                    />
                                </div>
                            )}
                            <div className="absolute bottom-0 right-0 z-above px-[15px]">
                                <ButtonsAction product={props.product} />
                            </div>
                        </div>
                        <div className="w-full px-[15px]">
                            <ExtendedNextLink
                                href={productHref}
                                type="product"
                                className="mb-[5px] block h-[60px] overflow-hidden break-words text-default font-base leading-5 text-primary no-underline hover:underline"
                                data-testid={TEST_IDENTIFIER + 'name'}
                                onClick={() =>
                                    onGtmProductClickEventHandler(
                                        props.product,
                                        props.gtmListName,
                                        props.listIndex,
                                        url,
                                    )
                                }
                            >
                                {props.product.fullName}
                            </ExtendedNextLink>
                            <ProductInformationOnTile
                                parameters={props.product.parametersOnList}
                                testIdentifier={TEST_IDENTIFIER + 'params'}
                                paramsVariant={ParamsVariantEnum.Regular}
                                informationTypeOnTile={props.product.informationTypeOnTile}
                                advantages={props.product.advantages}
                                shortDescription={props.product.shortDescription}
                            />
                        </div>
                        <div className="flex flex-1 flex-col justify-end px-[15px] pb-5">
                            <div className="flex">
                                {props.product.__typename !== 'MainVariant' && (
                                    <span className="mb-[10px] text-extraVerySmall font-semiBold text-base">
                                        {t('Item code')}: {props.product.catalogNumber}
                                    </span>
                                )}
                                {props.product.__typename === 'Variant' && (
                                    <div className="mb-[10px] ml-auto text-extraVerySmall font-semiBold text-base">
                                        <strong>{props.product.mainVariant?.variantsCount ?? 0}</strong>&nbsp;
                                        {t('(0)[Variants];(1)[Variant];(2-inf)[Variants];', {
                                            postProcess: 'interval',
                                            count: props.product.mainVariant?.variantsCount ?? 0,
                                        })}
                                    </div>
                                )}
                            </div>
                            <DiscountPrice
                                {...mapProductPriceData(props.product.price, currencyCode, props.product.stockUnitName)}
                                smallVariant
                                withGap
                            />
                            <div className="mb-[13px] flex flex-row flex-nowrap items-end justify-between">
                                <ProductPrice
                                    {...mapProductPriceData(
                                        props.product.price,
                                        currencyCode,
                                        props.product.stockUnitName,
                                    )}
                                />
                                <div className="ml-[5px]">
                                    <ProductAction
                                        product={props.product}
                                        gtmListName={props.gtmListName}
                                        gtmMessageOrigin={props.gtmMessageOrigin}
                                        listIndex={props.listIndex}
                                        buttonVariant="simple"
                                    />
                                </div>
                            </div>
                            <div
                                className="flex flex-col justify-end text-center text-littleSmall font-bold leading-[19px]"
                                data-testid={TEST_IDENTIFIER + 'availability'}
                                style={{ height: props.productAvailabilityHeight }}
                            >
                                <div ref={props.productAvailabilityRef}>
                                    <span
                                        className={twJoin(
                                            props.product.availability.status,
                                            isProductToDelivery(props.product) && 'to-delivery',
                                        )}
                                    >
                                        {props.product.availability.name}&nbsp;
                                        {props.product.availability.status !== 'immediately' &&
                                            props.product.__typename === 'MainVariant' &&
                                            props.product.colorVariants.length > 0 &&
                                            props.product.stockQuantity > 0 &&
                                            t('up to {{ quantity }} {{ unitName }}', {
                                                quantity: props.product.stockQuantity,
                                                unitName: props.product.unit.name,
                                            })}
                                        {props.product.availability.status !== 'immediately' &&
                                            props.product.__typename !== 'MainVariant' &&
                                            props.product.stockQuantity > 0 &&
                                            `${props.product.stockQuantity} ${props.product.unit.name}`}
                                    </span>
                                    {showStockOnTheWay && (
                                        <StockOnTheWay
                                            stockOnTheWay={props.product.stockOnTheWay}
                                            moreInPopup
                                            textCenter={true}
                                            data-testid={TEST_IDENTIFIER + 'onTheWay'}
                                            className={props.product.availability.status}
                                            productSlug={stockOnTheWaySlug}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProductsSliderItem;
